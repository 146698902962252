import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { getFollowStatsNumberAfterSync, getPostStatsNumberAfterSync } from '@/helper/utils'
import { ProfileModel } from '@/models/profile-model'
import { apiService } from '@/services/api-services'
import { dispatcher, FollowingProfile, PostActionModel } from '@/stores/dispatcher'
import { PostStore } from '@/stores/post-store'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export class DiscoveryViewmodel {
  @observable topPosts: PostStore[] = []
  @observable topPostOption = 'today'
  @observable usersToFollow: ProfileModel[] = []
  @observable communities: any[] = []
  @observable loadingCommunities = false
  @observable fetchingReview = false
  @observable reviews: PostStore[] = []
  @observable reviewsDisplayed: PostStore[] = []
  @observable seeMoreReview = false
  @observable page = 0
  @observable totalReviews = 0
  @observable seeMoreTopPosts = false
  @observable topPostsDisplayed: PostStore[] = []
  @observable userToFollowFilter = { page: 0, pageSize: 6 }
  @observable userToFollowTotalPage = 0
  @observable userToFollowFetching = false

  @observable topPostFilter = { _start: 0, _limit: 9, _sort: 'topScore:desc', type: 'everyone' }
  @observable topPostTotal = 0
  @observable topPostFetching = false

  @observable daoDisplayed: any[] = []
  @observable daoFilter = { _start: 0, _limit: 12 }
  @observable daoTotal = 0
  @observable daoFetching = false
  @observable seeMoreDaos = false

  @observable reviewFilter = { _start: 0, _limit: 12, type: 'review', _sort: 'score:desc' }
  @observable reviewTotal = 0
  @observable reviewFetching = false

  private _disposers: IReactionDisposer[]
  private _unsubcrible = new Subject()

  constructor() {
    this.fetchPosts(this.topPostFilter)
    this.fetchUsersToFollow(this.userToFollowFilter)
    this.fetchDaos(this.daoFilter)
    this.fetchReviewToRead(this.reviewFilter)
    this._disposers = [
      reaction(
        () => this.userToFollowFilter,
        (filter) => {
          if (filter) {
            this.fetchUsersToFollow(filter)
          }
        }
      ),
      reaction(
        () => this.topPostFilter,
        (filter) => {
          if (filter) {
            this.fetchPosts(filter)
          }
        }
      ),
      reaction(
        () => this.daoFilter,
        (filter) => {
          if (filter) {
            this.fetchDaos(filter)
          }
        }
      ),
      reaction(
        () => this.reviewFilter,
        (filter) => {
          if (filter) {
            this.fetchReviewToRead(filter)
          }
        }
      ),
    ]

    dispatcher.$profileChanged.pipe(takeUntil(this._unsubcrible)).subscribe((profile) => {
      this.syncFollowProfile(profile)
    })

    dispatcher.$postChanged.pipe(takeUntil(this._unsubcrible)).subscribe((postAction) => {
      this.syncPostAction(postAction)
    })
  }

  @action syncFollowProfile(profile: FollowingProfile) {
    if (!this.usersToFollow?.length) return
    const userIndex = this.usersToFollow.findIndex((item) => item._id === profile.to)
    if (userIndex !== -1) {
      this.usersToFollow[userIndex] = {
        ...this.usersToFollow[userIndex],
        isFollowing: profile.followState,
        loading: false,
        totalFollowers: getFollowStatsNumberAfterSync(
          this.usersToFollow[userIndex].totalFollowers,
          profile.followState
        ),
      }
    }
  }

  @action syncPostAction(postAction: PostActionModel) {
    let userIndex
    if (this.usersToFollow?.length) {
      userIndex = this.usersToFollow.findIndex((item) => item._id === postAction.owner)
      if (userIndex !== -1) {
        this.usersToFollow[userIndex] = {
          ...this.usersToFollow[userIndex],
          totalPosts: getPostStatsNumberAfterSync(this.usersToFollow[userIndex].totalPosts, postAction.type),
        }
      }
    }
    if (this.daoDisplayed?.length && postAction.daoId) {
      userIndex = this.daoDisplayed.findIndex((item) => item._id === postAction.daoId)
      if (userIndex !== -1) {
        this.daoDisplayed[userIndex] = {
          ...this.daoDisplayed[userIndex],
          totalPosts: getPostStatsNumberAfterSync(this.daoDisplayed[userIndex].totalPosts, postAction.type),
        }
      }
    }
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this.topPosts.forEach((postStore) => postStore.destroy())
    this._disposers.forEach((d) => d())
  }
  @action.bound setTopPostOption(val: string): void {
    if (val === this.topPostOption) return
    else this.topPostOption = val
  }

  @asyncAction *fetchPosts(filter: { _start: number; _limit: number }) {
    try {
      this.topPostFetching = true
      const res = yield apiService.posts.getPosts(filter)
      if (res) {
        const topPosts = res.data
        this.topPostTotal = res.total
        this.topPosts = topPosts.map((item) => new PostStore(item))
        this.topPostsDisplayed = this.topPosts.slice(0, 3)
      }
    } catch (e) {
    } finally {
      this.topPostFetching = false
    }
  }

  @asyncAction *fetchUsersToFollow(filter: { page: number; pageSize: number }) {
    try {
      this.userToFollowFetching = true
      const res = yield apiService.profiles.getUsersToFollow(filter)
      if (res) {
        this.usersToFollow = (res.data as ProfileModel[]).map((profile) => {
          return {
            ...profile,
            loading: false,
          }
        })
        this.userToFollowTotalPage = res.totalPage
      }
    } catch (error) {
    } finally {
      this.userToFollowFetching = false
    }
  }

  @asyncAction *fetchDaos(filter: any) {
    try {
      this.loadingCommunities = true
      const res = yield apiService.daos.getDaosToJoin(filter)
      if (res) {
        this.communities = res.data
        this.daoTotal = res.total
        this.daoDisplayed = this.communities.slice(0, 4)
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.loadingCommunities = false
    }
  }

  @asyncAction *fetchReviewToRead(filter: any) {
    try {
      this.fetchingReview = true
      const reviews = yield apiService.posts.fetchPosts(filter)
      if (reviews.length) {
        this.totalReviews = reviews[0].total
        this.reviews = reviews.map((item) => new PostStore(item))
        this.reviewsDisplayed = this.reviews.slice(0, 4)
      }
    } catch (error) {
    } finally {
      this.fetchingReview = false
    }
  }

  @action.bound handleSeeMoreReview() {
    if (!this.seeMoreReview) {
      this.reviewsDisplayed = this.reviews
      this.seeMoreReview = true
    }
  }

  @action.bound handleSeeMoreTopPost() {
    if (!this.seeMoreTopPosts) {
      this.topPostsDisplayed = this.topPosts
      this.seeMoreTopPosts = true
    }
  }

  @action.bound handleSeeMoreDaos() {
    if (!this.seeMoreDaos) {
      this.daoDisplayed = this.communities
      this.seeMoreDaos = true
    }
  }

  @action.bound changeTopPostFilter() {
    const filter = { ...this.topPostFilter, _start: this.topPostFilter._start + this.topPostFilter._limit }
    if (filter._start >= this.topPostTotal) {
      this.topPostFilter = { ...this.topPostFilter, _start: 0 }
    } else {
      this.topPostFilter = filter
    }
    this.seeMoreTopPosts = false
  }

  @action.bound changeReviewFilter() {
    const filter = { ...this.reviewFilter, _start: this.reviewFilter._start + this.reviewFilter._limit }
    if (filter._start >= this.reviewTotal) {
      this.reviewFilter = { ...this.reviewFilter, _start: 0 }
    } else {
      this.reviewFilter = filter
    }
    this.seeMoreReview = false
  }

  @action.bound changeDaoFilter() {
    const filter = { ...this.daoFilter, _start: this.daoFilter._start + this.daoFilter._limit }
    if (filter._start >= this.daoTotal) {
      this.daoFilter = { ...this.daoFilter, _start: 0 }
    } else {
      this.daoFilter = filter
    }
    this.seeMoreDaos = false
  }

  @action.bound changeUserToFollowFilter() {
    try {
      if (this.userToFollowFilter.page >= this.userToFollowTotalPage - 1)
        this.userToFollowFilter = { page: 0, pageSize: 6 }
      else this.userToFollowFilter = { ...this.userToFollowFilter, page: this.userToFollowFilter.page + 1 }
    } catch (error) {}
  }

  @computed get showTopPostUpdateList() {
    return this.topPostTotal >= this.topPostFilter._limit
  }

  @computed get showUserUpdateList() {
    return this.userToFollowTotalPage > 1
  }

  @computed get showDaoUpdateList() {
    return this.daoTotal >= this.daoFilter._limit
  }

  @computed get showReviewUpdateList() {
    return this.reviewTotal >= this.reviewFilter._limit
  }

  @computed get showSeeMoreTopPost() {
    return this.topPostsDisplayed.length < this.topPosts.length
  }

  @computed get showSeeMoreDaos() {
    return this.daoDisplayed.length < this.communities.length
  }

  @computed get showSeeMoreReview() {
    return this.reviewsDisplayed.length < this.reviews.length
  }
}
