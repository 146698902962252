
















































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { DiscoveryViewmodel } from '@/modules/community/home/viewmodels/discovery-viewmodel'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'

@Observer
@Component({
  components: {
    'owner-section': () => import('@/modules/common/components/section/owner-section.vue'),
    'my-dao-section': () => import('@/modules/common/components/section/my-dao-section.vue'),
    'trending-card': () => import('@/modules/community/home/components/review-components/trending-card.vue'),

    'top-post': () => import('@/modules/community/home/components/discover-components/top-post.vue'),
    'user-to-follow': () => import('@/modules/community/home/components/discover-components/user-to-follow.vue'),
    'dao-to-join': () => import('@/modules/community/home/components/discover-components/dao-to-join.vue'),
    'review-read': () => import('@/modules/community/home/components/discover-components/review-read.vue'),
  },
})
export default class Discovery extends Vue {
  createPostController = createPostController
  @Provide() vm = new DiscoveryViewmodel()

  dates = ['today', 'week', 'Month', 'all']

  destroyed() {
    this.vm.destroy()
  }
}
